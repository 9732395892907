/// <reference types="google.accounts, jsonwebtoken" />
<script setup lang="ts">
import { toRefs } from 'vue';
import { User } from '../models/link';
import { useUserStore } from '../store/user';
import FreeClickProgress from './FreeClickProgress.vue';

const props = defineProps<{user: User, showDrawer: boolean}>();
const { user, showDrawer } = toRefs(props);

const userStore = useUserStore();

function signout() {
    userStore.logout();
}
</script>

<template>

<v-navigation-drawer
    v-model="showDrawer"
    location="left"
    temporary
    >
    <v-list-item
        :prepend-avatar="user.imageUrl"
        :title="user.name"
    >
        <template v-slot:subtitle>
            <a class="sign-out" @click.stop.prevent="signout">Sign out</a>
        </template>
    </v-list-item>

    <v-divider></v-divider>

    <v-list v-if="user.ownedChannels">
        <template v-for="(item, _) in user.ownedChannels">
            <v-list-subheader><v-avatar :image="item.imageUrl"></v-avatar> {{item.name}}</v-list-subheader>
            <FreeClickProgress :click-count="1500" :free-click-count="2000"/>
            <v-list-item
                :to="`/channels/${item.id}`"
                color="primary"
                variant="plain"
            >Channel page</v-list-item>
            <v-list-item
                :to="`/channels/${item.id}/metrics`"
                color="primary"
                variant="plain"
            >Metrics</v-list-item>
            <v-list-item
                :to="`/channels/${item.id}/settings`"
                color="primary"
                variant="plain"
            >Settings</v-list-item>
        </template>
    </v-list>
</v-navigation-drawer>

</template>
<style scoped>
.sign-out {
    cursor: pointer;
}
</style>